<template>
  <div>
    <h1>{{ `${isEdit ? "Edit" : "New"} Article` }}</h1>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-form @submit.prevent="save()">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="articleModel.title" label="Title" required></v-text-field>
            </v-col>
            <v-col sm="3">
              <h3>Feature Image</h3>
              <v-img :aspect-ratio="16 / 9" @click="triggerFeatureFile" v-if="articleModel.featurePicture" :src="articleModel.featurePicture" class="pointer img_upload--preview"></v-img>
              <div v-else class="img_upload--preview">
                <v-img :aspect-ratio="16 / 9" @click="triggerFeatureFile" :src="require('@/assets/img/NLA-logo-placeholder.jpg')" class="pointer"></v-img>
              </div>
            </v-col>
            <v-file-input v-model="articleModel.newFeaturePicture" accept="image/*" ref="article_feature_img" class="d-none"></v-file-input>
            <v-col sm="3">
              <h3>Header Image (Use an image with height 400px)</h3>
              <v-img @click="triggerHeaderFile" :aspect-ratio="16 / 9" v-if="articleModel.headerPicture" :src="articleModel.headerPicture" class="pointer img_upload--preview" contain></v-img>
              <div v-else class="pointer img_upload--preview">
                <v-img @click="triggerHeaderFile" :aspect-ratio="16 / 9" :src="require('@/assets/img/NLA-logo-placeholder.jpg')" class="pointer"></v-img>
              </div>
            </v-col>
            <v-file-input v-model="articleModel.newHeaderPicture" accept="image/*" ref="article_header_img" class="d-none"></v-file-input>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="articleModel.featureContent" filled label="Feature Content (Content to appear on selection card)" auto-grow required></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-textarea v-model="articleModel.content" filled label="Content" auto-grow required></v-textarea> -->
              <label for="input-106" class="v-label v-label--active theme--light" style="left: 0px; right: auto;">Article Content</label>
              <Editor
                v-model="articleModel.content"
                api-key="amaf517w2w5h9ptobxbv2rbz7b9aosza1unbcwrd50ffmugr"
                :init="{
                  plugins: 'lists link image table code help wordcount',
                }"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pl-4">
          <v-btn type="submit" color="success">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { Article } from "@/models/Article";
import { gql } from "apollo-boost";
import { getFileAsDataURL } from "@/helpers/functions";
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "ArticleEdit",
  components: {
    Editor,
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      articleModel: new Article(),
      me: "",
      articlesList: [],
      prices: {},
      search: null,
      crumbs: [
        {
          text: "Articles",
          disabled: false,
          href: "/admin/articles",
        },
        {
          text: "Article Details",
          disabled: false,
          href: `/admin/article/${this.$route.params.id}`,
        },
        {
          text: "Article Edit",
          disabled: true,
        },
      ],
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    article: {
      query: gql`
        query Article($id: ID!) {
          article(id: $id) {
            id
            title
            content
            featureContent
            featurePicture
            headerPicture
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.articleModel.id = this.article.id;
        this.articleModel.title = this.article.title;
        this.articleModel.content = this.article.content;
        this.articleModel.featureContent = this.article.featureContent;
        this.articleModel.featurePicture = this.article.featurePicture;
        this.articleModel.headerPicture = this.article.headerPicture;
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (self.isEdit) {
        // update
        await this.updateArticle()
          .then(() => {
            this.$swal({
              title: "Success",
              text: "Article was updated",
              icon: "success",
              confirmButtonColor: "#c79477",
            });
          })
          .catch((e) => {
            this.$swal({
              title: "Error",
              text: "Something went wrong, please check data and try again later!",
              icon: "error",
              confirmButtonColor: "#c79477",
            });
            console.log(e);
          });
      } else {
        // create
        await this.saveCreate()
          .then(() => {
            window.location.href = `/admin/article/${self.articleModel.id}/edit`;
          })
          .catch((e) => {
            this.$swal({
              title: "Error",
              text: "Something went wrong, please check data and try again later!",
              icon: "error",
              confirmButtonColor: "#c79477",
            });
            console.log(e);
          });
      }
      this.isLoading = false;
    },
    async saveCreate() {
      const self = this;
      await this.createArticle().then((articleId) => {
        self.articleModel.id = articleId;
      });
    },
    async createArticle() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createArticle($article: ArticleCreateInput!) {
              createArticle(article: $article) {
                id
              }
            }
          `,
          variables: {
            article: {
              title: self.articleModel.title,
              content: self.articleModel.content,
              featureContent: self.articleModel.featureContent,
              createdBy: self.me.id,
            },
          },
        });

        if (response) {
          if (response?.data?.createArticle?.id) {
            if (self.articleModel.featurePicture) await self.addFeaturePicture(response?.data?.createArticle?.id);
            if (self.articleModel.headerPicture) await self.addHeaderPicture(response?.data?.createArticle?.id);
            return response.data.createArticle.id;
          }
        }
      } catch (e) {
        this.$swal({
          title: "Error",
          text: e.message,
          icon: "error",
          confirmButtonColor: "#c79477",
        });
      }
    },
    async addFeaturePicture(id) {
      const self = this;
      const check = self.articleModel.newFeaturePicture instanceof File;
      if (!check) return;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateArticle($article: ArticleUpdateInput!) {
              updateArticle(article: $article) {
                id
                updated
              }
            }
          `,
          variables: {
            article: {
              id: id,
              featurePicture: self.articleModel.featurePicture,
            },
          },
        });
      } catch (e) {
        console.log(`Update Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        console.log(self.errorMsg);
      }
    },
    async addHeaderPicture(id) {
      const self = this;
      const check = self.articleModel.newHeaderPicture instanceof File;
      if (!check) return;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateArticle($article: ArticleUpdateInput!) {
              updateArticle(article: $article) {
                id
                updated
              }
            }
          `,
          variables: {
            article: {
              id: id,
              headerPicture: self.articleModel.headerPicture,
            },
          },
        });
      } catch (e) {
        console.log(`Update Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        console.log(self.errorMsg);
      }
    },
    async updateArticle() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateArticle($article: ArticleUpdateInput!) {
              updateArticle(article: $article) {
                id
                updated
                article {
                  featurePicture
                  headerPicture
                }
              }
            }
          `,
          variables: {
            article: {
              id: self.articleModel.id,
              title: self.articleModel.title,
              content: self.articleModel.content,
              featureContent: self.articleModel.featureContent,
            },
          },
        });

        if (response) {
          this.addFeaturePicture(self.articleModel.id);
          this.addHeaderPicture(self.articleModel.id);
          console.log(response);
        }
      } catch (e) {
        console.log(`Update Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        console.log(self.errorMsg);
      }
    },
    triggerFeatureFile() {
      this.$refs.article_feature_img.$refs.input.click();
    },
    triggerHeaderFile() {
      this.$refs.article_header_img.$refs.input.click();
    },
  },
  watch: {
    "articleModel.newFeaturePicture": async function(newValue, oldValue) {
      this.articleModel.featurePicture = await getFileAsDataURL(newValue);
    },
    "articleModel.newHeaderPicture": async function(newValue, oldValue) {
      this.articleModel.headerPicture = await getFileAsDataURL(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.img_upload {
  &--preview {
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
.document_upload {
  &--preview {
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
</style>
