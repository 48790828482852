export class Article {
  constructor() {
    this.id = "";
    this.title = "";
    this.content = "";
    this.featureContent = "";
    this.featurePicture = "";
    this.headerPicture = "";
  }
}
